import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ListItemIcon,
  withStyles,
  Theme,
  IconButton,
  SvgIcon,
} from "@material-ui/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

const styles = (theme: Theme) => ({
  ellipsisMenu: {
    marginRight: "8px",
  },
});

interface PaperCardMenuProps {
  classes: ClassNameMap;
  items: Array<{
    title: string;
    action: (event: React.MouseEvent<any>) => void;
    icon?: string;
    color?: string;
  }>;
}

function PaperCardMenu({ items, classes }: PaperCardMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & SVGSVGElement) | null
  >(null);

  let onItemClick =
    (action: (event: React.MouseEvent<any>) => void) =>
    (event: React.MouseEvent<any>) => {
      event.stopPropagation();
      action(event);
      setAnchorEl(null);
    };

  let menuItems = items.map((item) => {
    let icon = item.icon ? (
      <ListItemIcon>
        <FontAwesomeIcon
          color={item.color ? item.color : undefined}
          icon={item.icon as IconProp}
        />
      </ListItemIcon>
    ) : undefined;
    return (
      <MenuItem
        style={item.color ? { color: item.color } : {}}
        key={item.title}
        onClick={onItemClick(item.action)}
      >
        {icon}
        {item.title}
      </MenuItem>
    );
  });

  const handleClick = (event: React.MouseEvent<any>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <SvgIcon>
          <FontAwesomeIcon
            className={classes.ellipsisMenu}
            icon="ellipsis-v"
            color="grey"
          />
        </SvgIcon>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems}
      </Menu>
    </>
  );
}

export default withStyles(styles)(PaperCardMenu);
