import axios from "axios";
import { Spell } from "../reducers/interfaceData";
import { getToken } from "./Account";
import { SpellbookProfile } from "../reducers/spellbookData";
import { UserSpell } from "../containers/CustomSpells/CustomSpellInterface";

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Get the details of a spell from the API
 * @param spellId
 * @returns
 */
export async function getSpellInfo(spellId: number): Promise<Spell> {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "get",
        url: API_URL + "/v1/spellbook/spells/" + spellId,
        params: {},
      });

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

//

/**
 * Get whether the spell list has been updated from the API
 * @param spellId
 * @returns
 */
export async function getSpellsChanged(): Promise<{ lastUpdate: number }> {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "get",
        url: API_URL + "/v1/spellbook/spells/check",
        params: {},
      });

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * Get the list of spells from the API
 * @returns
 */
export async function getSpells(): Promise<{ [spellId: string]: Spell }> {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "get",
        url: API_URL + "/v1/spellbook/spells/list",
        params: {},
      });

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * Get the list of catergorized spells from the API
 * @returns
 */
export async function getCategorizedSpells(): Promise<{
  [className: string]: {
    [level: string]: Array<string>;
  };
}> {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "get",
        url: API_URL + "/v1/spellbook/spells/categorized",
        params: {},
      });

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * Sync profiles
 */
export async function syncProfiles(cloudProfiles: {
  [uuid: string]: SpellbookProfile;
}): Promise<{ profiles: Array<SpellbookProfile>; deleted: Array<string> }> {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        API_URL + "/v1/spellbook/profiles/sync",
        {
          profiles: cloudProfiles,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      // if the request is a success, dispatch the profiles.
      if (response.status === 200) {
        resolve({
          profiles: response.data.profiles,
          deleted: response.data.deleted,
        });
      } else {
        console.log(response);
        reject("Cloud sync failed: " + response.status);
      }
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
}

/**
 * Delete a profile
 * @param uuid
 * @returns
 */
export async function deleteProfile(uuid: string): Promise<boolean> {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "delete",
        url: API_URL + `/v1/spellbook/profiles/${uuid}`,
        params: {},
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      if (response.status === 204) {
        resolve(true);
      } else {
        reject(new Error("Profile does not exist"));
      }
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * Sync custom spells
 */
export async function syncCustomSpells(cloudCustomSpells: {
  [id: string]: UserSpell;
}): Promise<SpellSyncResponse> {
  return new Promise(async (resolve, reject) => {
    let transformedSpells: SpellSyncList = {};
    for (const [key, value] of Object.entries(cloudCustomSpells)) {
      transformedSpells[key] = {
        name: value.data?.name || value.draftData?.name || "",
        school: value.data?.school || value.draftData?.school || "",
        version: value.version || 0,
        data: value,
      };
    }

    try {
      let response = await axios.post(
        API_URL + "/v1/spellbook/spells/sync",
        {
          spells: transformedSpells,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      // if the request is a success, dispatch the profiles.
      if (response.status === 200) {
        resolve(response.data);
      } else {
        console.error(response);
        reject("Cloud custom spell sync failed: " + response.status);
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}

interface SpellSyncList {
  [id: string]: {
    name: string;
    school: string;
    version: number;
    data: UserSpell;
  };
}

interface SpellSyncResponse {
  spells: SpellSyncList;
  deleted: string[];
}

/**
 * Delete a user spell
 * @param uuid the spell id
 * @returns
 */
export async function deleteUserSpell(uuid: string): Promise<boolean> {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "delete",
        url: API_URL + `/v1/spellbook/spells/${uuid}`,
        params: {},
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      if (response.status === 204) {
        resolve(true);
      } else {
        resolve(false);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export async function getChangelog(): Promise<ChangelogResponse> {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "get",
        url: API_URL + "/v1/changelogs/spellbook/latest",
        params: {},
      });

      resolve({
        version: response.data.version,
        date: response.data.date,
        highlights: response.data.highlights,
        features: response.data.features,
        bugs: response.data.bugs,
      });
    } catch (error) {
      reject(error);
    }
  });
}

export interface ChangelogResponse {
  version: string;
  date: Date;
  highlights: Array<string>;
  features: Array<string>;
  bugs: Array<string>;
}
