import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Paper,
  Tooltip,
  Theme,
  createStyles,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faCheckCircle,
  faCloud,
  faHome,
  faExclamationTriangle,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  selectSpellbook,
  addSpellbook,
  moveProfileToCloud,
  moveProfileToLocal,
  deleteProfile,
} from "../../actions/spellbookActions";
import { isLoggedIn } from "../../tools/Account";
import { State } from "../../reducers";
import { SpellbookData, SpellbookProfile } from "../../reducers/spellbookData";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import PaperCardMenu from "../../components/PaperCardMenu";
import { red } from "@material-ui/core/colors";

interface ProfilesContainerProps {
  classes: ClassNameMap;
  spellbookData: SpellbookData;
  addSpellbook: () => void;
  openSettings: () => void;
  moveProfileToCloud: (profileId: string) => void;
  moveProfileToLocal: (profileId: string) => void;
  selectSpellbook: (profileId: string) => void;
  deleteProfile: (profileId: string) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      //margin: theme.spacing(2),
    },
    fullWidth: {
      width: "100%",
    },
    helpText: {
      color: "#888",
      textAlign: "center",
    },
    profileTypes: {
      textAlign: "center",
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
    spellBookTitle: {
      color: "#333", //theme.palette.primary.main,
      fontWeight: 500,
    },
    spellBookClasses: {
      color: "#555",
      fontWeight: 300,
    },
    spellBookType: {
      color: "#AAA",
      fontWeight: 300,
    },
    spellBookItem: {
      minHeight: 75,
      width: "100%",
      padding: theme.spacing(2),
      backgroundColor: "#EEE",
      cursor: "pointer",
    },
    selectedSpellBookItem: {
      boxShadow: "inset 0px 0px 3px 3px " + theme.palette.primary.main,
    },
    addNewItem: {
      height: 50,
      width: "100%",
      padding: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      color: "#FFF",
      cursor: "pointer",
    },
    cloudWarningText: {
      height: 50,
      width: "100%",
      textAlign: "center",
      padding: theme.spacing(2),
      color: "#e74c3c",
    },
    cloudButton: {
      minHeight: 75,
      height: "100%",
      padding: theme.spacing(2),
      backgroundColor: theme.palette.secondary.main,
      cursor: "pointer",
    },
    cloudButtonGrid: {
      height: 45,
    },
  });

function ProfilesContainer(props: ProfilesContainerProps) {
  const { classes } = props;

  let localProfiles = [];
  let cloudProfiles = [];

  let addSpellbookFunc = () => {
    props.addSpellbook();
  };

  let switchCloudLocal = (profileId: string, profile: SpellbookProfile) => {
    if (profile.type === "local") {
      props.moveProfileToCloud(profileId);
    } else {
      props.moveProfileToLocal(profileId);
    }
  };
  for (let [key, profile] of Object.entries(props.spellbookData.profiles)) {
    let moveToCloudButton = (
      <Grid item>
        <Tooltip title="Upload to cloud" arrow>
          <Paper
            className={classes.cloudButton}
            onClick={(event) => {
              switchCloudLocal(key, profile);
            }}
          >
            <Grid
              className={classes.cloudButtonGrid}
              justifyContent="space-between"
              alignItems="center"
              direction="column"
              container
            >
              <Grid item>
                <FontAwesomeIcon
                  color="#2980b9"
                  size="1x"
                  icon={faCloud as IconProp}
                ></FontAwesomeIcon>
              </Grid>
              <Grid item>
                <FontAwesomeIcon
                  color="#2980b9"
                  size="1x"
                  icon={faArrowDown as IconProp}
                ></FontAwesomeIcon>
              </Grid>
            </Grid>
          </Paper>
        </Tooltip>
      </Grid>
    );

    let element = (
      <Grid className={classes.fullWidth} key={key} item>
        <Grid className={classes.fullWidth} direction="row" container>
          <Grid xs item>
            <Paper
              onClick={(event) => {
                props.selectSpellbook(key);
              }}
              className={
                key === props.spellbookData.selectedProfileId
                  ? `${classes.spellBookItem} ${classes.selectedSpellBookItem}`
                  : `${classes.spellBookItem}`
              }
            >
              <Grid
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                container
              >
                <Grid item>
                  <Typography className={classes.spellBookTitle}>
                    {profile.current.name}
                  </Typography>
                  <Typography className={classes.spellBookClasses}>
                    {profile.current.classes.join(", ")}
                  </Typography>
                </Grid>
                <Grid style={{ width: "42px" }} item>
                  <Grid direction="row" spacing={1} container>
                    <Grid item>
                      <PaperCardMenu
                        items={[
                          {
                            title: "Select",
                            action: () => {
                              props.selectSpellbook(key);
                            },
                            icon: "check",
                          },
                          {
                            title: "Delete",
                            action: () => {
                              props.deleteProfile(key);
                            },
                            icon: "trash",
                            color: red[500],
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {profile.type === "local" ? moveToCloudButton : undefined}
        </Grid>
      </Grid>
    );

    if (profile.type === "local") {
      localProfiles.push(element);
    } else {
      cloudProfiles.push(element);
    }
  }

  return (
    <div className={classes.root}>
      <Grid
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        container
      >
        <Grid className={classes.fullWidth} item>
          <Typography className={classes.helpText}>
            Select the spellbook you want to work with.
          </Typography>
        </Grid>
        <Grid className={classes.fullWidth} item>
          <Grid
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
            container
          >
            {!isLoggedIn() && cloudProfiles.length > 0 ? (
              <Grid className={classes.cloudWarningText} item>
                <FontAwesomeIcon
                  color="#e74c3c"
                  size="1x"
                  icon={faExclamationTriangle as IconProp}
                ></FontAwesomeIcon>{" "}
                You are not logged in, cloud spellbooks will not be
                synchronized!
              </Grid>
            ) : undefined}
            <Grid className={classes.fullWidth} key="add_new" item>
              <Paper className={classes.addNewItem} onClick={addSpellbookFunc}>
                <Grid
                  alignContent="center"
                  justifyContent="center"
                  spacing={2}
                  container
                >
                  <Grid item>
                    <FontAwesomeIcon
                      color="#fff"
                      size="1x"
                      icon={faPlusCircle as IconProp}
                    ></FontAwesomeIcon>
                  </Grid>
                  <Grid item>Add a new spellbook</Grid>
                </Grid>
              </Paper>
            </Grid>
            {localProfiles.length > 0
              ? [
                  <Grid
                    className={classes.fullWidth}
                    key={"local-profiles"}
                    item
                  >
                    <Typography className={classes.profileTypes}>
                      <FontAwesomeIcon
                        color="#833471"
                        size="1x"
                        icon={faHome as IconProp}
                      ></FontAwesomeIcon>{" "}
                      Local spellbooks
                    </Typography>
                  </Grid>,
                  ...localProfiles,
                ]
              : undefined}

            {cloudProfiles.length > 0
              ? [
                  <Grid
                    className={classes.fullWidth}
                    key={"cloud-profiles"}
                    item
                  >
                    <Typography className={classes.profileTypes}>
                      <FontAwesomeIcon
                        color="#833471"
                        size="1x"
                        icon={faCloud as IconProp}
                      ></FontAwesomeIcon>{" "}
                      Cloud spellbooks
                    </Typography>
                  </Grid>,
                  ...cloudProfiles,
                ]
              : undefined}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = {
  selectSpellbook,
  addSpellbook,
  moveProfileToCloud,
  moveProfileToLocal,
  deleteProfile,
};

const mapStateToProps = (state: State, ownProps: any) => ({
  ...ownProps,
  spellbookData: state.spellbookData,
});

export default compose<
  React.FunctionComponent<Partial<ProfilesContainerProps>>
>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ProfilesContainer);
