import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import SpellSchoolIcons from "../themes/SpellSchoolIcons";
import { Theme } from "@material-ui/core/styles";
import React, { useState } from "react";
import { ClassNameMap } from "@material-ui/styles";
import PaperCardMenu from "./PaperCardMenu";
import { red } from "@material-ui/core/colors";

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: "100%",
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    maxWidth: "100%",
  },
  textGridItem: {
    paddingLeft: theme.spacing(2),
    maxWidth: "72%",
  },
  spellNameText: {
    alignText: "left",
    paddingLeft: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 500,
  },
  spellClassText: {
    color: "darkgray",
    fontSize: 12,
  },
  deleteButton: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
});

interface SpellDraftCardProps {
  className?: string;
  spellName: string;
  spellSchool: string;
  lastModified: Date;
  deleteDraft: () => void;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  classes: ClassNameMap;
}

function SpellDraftCard({
  className,
  spellName,
  spellSchool,
  lastModified,
  deleteDraft,
  onClick,
  classes,
}: SpellDraftCardProps) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  return (
    <Paper className={`${classes.root} ${className ? className : ""}`}>
      <Grid
        direction="row"
        alignContent="space-between"
        alignItems="center"
        container
      >
        <Grid xs={1} style={{ width: 32, textAlign: "left" }} item>
          {typeof SpellSchoolIcons[spellSchool] !== "undefined" ? (
            <FontAwesomeIcon
              size="2xl"
              icon={SpellSchoolIcons[spellSchool].icon as IconProp}
              color={SpellSchoolIcons[spellSchool].color}
            />
          ) : (
            <FontAwesomeIcon size="2xl" icon="circle-question" color="gray" />
          )}
        </Grid>
        <Grid
          className={classes.textGridItem}
          xs
          direction="column"
          item
          container
        >
          <Grid xs item>
            <Typography className={classes.text} noWrap>
              {spellName}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.spellClassText} noWrap>
              {lastModified.toLocaleString()}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs style={{ textAlign: "right" }} item>
          <PaperCardMenu
            items={[
              { title: "Edit", action: onClick, icon: "pen" },
              {
                title: "Delete",
                action: () => {
                  setDeleteDialogOpen(true);
                },
                icon: "trash",
                color: red[500],
              },
            ]}
          />
          <Dialog open={deleteDialogOpen}>
            <DialogTitle>{"Delete"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this custom spell?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
              <Button
                className={classes.deleteButton}
                onClick={() => {
                  deleteDraft();
                }}
                variant="contained"
                autoFocus
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(styles)(SpellDraftCard);
