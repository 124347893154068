import React from "react";
import ReactDOM from "react-dom";
import { Application } from "./App";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { theme as themeData } from "./themes/theme";
import { Provider } from "react-redux";
import rootReducer, { State } from "./reducers";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import thunk from "redux-thunk";
import { upgrade } from "./upgrades";
import { isLoggedIn } from "./tools/Account";

import { actions as spellbookActions } from "./actions/spellbookActions";
import {
  actions as interfaceActions,
  retrieveCategorizedSpells,
  retrieveSpells,
} from "./actions/interfaceActions";

import { SpellbookProfile } from "./reducers/spellbookData";
import * as Sentry from "@sentry/react";
import {
  syncCustomSpells,
  syncProfiles,
  getChangelog,
} from "./tools/ApiManager";
import { UserSpell } from "./containers/CustomSpells/CustomSpellInterface";
import { getHashOfObject } from "./tools/Utilities";

Sentry.init({
  dsn: "https://de349c19957d4bea875f70cef9e6b1ac@o4505375803244544.ingest.sentry.io/4505375805603840",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/spellbook\.tabletop\.cloud/,
      ],
    }),
    new Sentry.Replay(),
  ],
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        title: "Oops! Looks like we rolled a natural 1.",
        subtitle:
          "Sorry, the spellbook seems to have ran into an issue! I've recorded the error and it is highly likely it will be resolved within a week. Could you provide me with some additional information to help me resolve this issue?",
      });
    }
    return event;
  },
  // Performance Monitoring
  tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }

    return upgrade(JSON.parse(serializedState));
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const saveState = (state: State) => {
  try {
    let suppliedState = Object.assign({}, state);
    let serializedState = JSON.stringify(suppliedState);

    //console.log(`Current local storage size: ${serializedState.length*2} bytes`);
    if (serializedState.length > 2359296) {
      // If the length of the state is too big we just delete the spell list and let the user download it again.
      suppliedState.interfaceData!.spellList = {};
      suppliedState.interfaceData!.spellListIsDownloading = false;
      serializedState = JSON.stringify(suppliedState);
    }

    localStorage.setItem("state", serializedState);
  } catch {
    // ignore write errors
  }
};

const persistedState: State = loadState();

if (persistedState && persistedState.spellbookData) {
  Sentry.setContext("SpellbookProfile", {
    profileId: persistedState.spellbookData.selectedProfileId,
  });
}
//Sentry.setContext("UserData", getUser());

const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
});

const middleware = [thunk];

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(...middleware))
);

store.subscribe(() => {
  saveState({
    spellbookData: store.getState().spellbookData,
    interfaceData: store.getState().interfaceData,
  });
});

// Cloud syncing
let performCloudSync = () => {
  performProfilesSync();
  performCustomSpellSync();
};

let performProfilesSync = async (retries: number = 0) => {
  console.log("Performing cloud sync...");
  let preUpdateState = store.getState().spellbookData.profiles;

  let cloudProfilesToSync: { [uuid: string]: SpellbookProfile } = {};
  // Save the new checksums so that we can update them after the sync
  let profileChecksums: {
    [uuid: string]: {
      beforeUpdate: string;
      afterUpdate?: string;
      remote?: string;
    };
  } = {};
  for (let [uuid, profile] of Object.entries(preUpdateState)) {
    profileChecksums[uuid] = { beforeUpdate: getHashOfObject(profile.current) };

    // Only upload cloud profile, and only ones that contain changes
    if (profile.type === "cloud") {
      if (profile.lastChecksum) {
        // check if the profile has changed by comparing the checksums
        if (profileChecksums[uuid].beforeUpdate !== profile.lastChecksum) {
          cloudProfilesToSync[uuid] = profile;
        }
      } else {
        cloudProfilesToSync[uuid] = profile;
      }
    }
  }

  syncProfiles(cloudProfilesToSync)
    .then((syncData) => {
      let profiles = syncData.profiles;
      let deletedProfiles = syncData.deleted;

      //console.log("Synced profiles: ", profiles);
      let currentProfiles = store.getState().spellbookData.profiles;
      //console.log("Changed profiles?: ", currentProfiles);
      let profilesToDispatch: { [uuid: string]: SpellbookProfile } = {};

      for (let [uuid, profile] of Object.entries(profiles)) {
        // get the most recent list of profiles for a new checksum calculation
        if (profileChecksums[uuid] !== undefined) {
          profileChecksums[uuid].remote = getHashOfObject(profile.current);
        } else {
          profileChecksums[uuid] = {
            beforeUpdate: "",
            remote: getHashOfObject(profile.current),
          };
        }

        if (currentProfiles[uuid]) {
          // This is an existing profile
          let currentProfileWithoutChecksum = Object.assign(
            {},
            currentProfiles[uuid]
          );
          delete currentProfileWithoutChecksum.lastChecksum;
          profileChecksums[uuid].afterUpdate = getHashOfObject(
            currentProfileWithoutChecksum.current
          );

          // If any profile has changed during the sync, we have to start over with syncing or new data might be lost
          if (
            profileChecksums[uuid].beforeUpdate ===
            profileChecksums[uuid].afterUpdate
          ) {
            profilesToDispatch[uuid] = {
              ...profile,
              version: profile.version,
              lastChecksum: profileChecksums[uuid].remote,
            };
          } else {
            //console.log("Restarting sync: ", uuid, profileChecksums[uuid]);
            setTimeout(() => {
              performProfilesSync(retries + 1);
            }, retries * 2 * 2 * 1000);
            return;
          }
        } else {
          // This is a new profile
          profilesToDispatch[uuid] = {
            ...profile,
            lastChecksum: profileChecksums[uuid].remote,
          };
        }

        //console.log("Profile updated: ", uuid, profileChecksums[uuid]);
      }

      store.dispatch({
        type: spellbookActions.CLOUD_SYNC,
        profiles: profilesToDispatch,
        deleted: deletedProfiles,
      });
    })
    .catch((error) => {
      console.log("Profile sync failed: ", error);
    });
};

let performCustomSpellSync = async (retries: number = 0) => {
  console.log("Performing custom spell sync...");
  let userSpells = store.getState().spellbookData.userSpells;

  if (typeof userSpells !== "undefined") {
    let spellsToSync: { [uuid: string]: UserSpell } = {};

    // Save the new checksums so that we can update them after the sync
    let spellChecksums: {
      [uuid: string]: {
        beforeUpdate: string;
        afterUpdate?: string;
        remote?: string;
      };
    } = {};
    for (let [uuid, spell] of Object.entries(userSpells)) {
      let spellWithoutChecksum = Object.assign({}, spell);
      delete spellWithoutChecksum.lastChecksum;

      spellChecksums[uuid] = {
        beforeUpdate: getHashOfObject(spellWithoutChecksum),
      };

      if (spell.lastChecksum) {
        // check if the spell has changed by comparing the checksums
        if (spellChecksums[uuid].beforeUpdate !== spell.lastChecksum) {
          spellsToSync[uuid] = spell;
        }
      } else {
        spellsToSync[uuid] = spell;
      }
    }

    syncCustomSpells(spellsToSync)
      .then((syncResponse) => {
        //console.log("Synced spells: ", syncResponse.spells);
        //console.log("Deleted spells: ", syncResponse.deleted);
        let currentSpells = store.getState().spellbookData.userSpells;

        let newSpellList: { [uuid: string]: UserSpell } = {};

        // Only the remote spells matter, because it always returns a list of all spells
        for (let [uuid, spell] of Object.entries(syncResponse.spells)) {
          // get the most recent list of spells for a new checksum calculation
          if (spellChecksums[uuid] !== undefined) {
            spellChecksums[uuid].remote = getHashOfObject(spell.data);
          } else {
            spellChecksums[uuid] = {
              beforeUpdate: "",
              remote: getHashOfObject(spell.data),
            };
          }

          if (currentSpells![uuid]) {
            // This is an existing spell
            let currentSpellWithoutChecksum = Object.assign(
              {},
              currentSpells![uuid]
            );
            delete currentSpellWithoutChecksum.lastChecksum;
            spellChecksums[uuid].afterUpdate = getHashOfObject(
              currentSpellWithoutChecksum
            );

            // If any spell has changed during the sync, we have to start over with syncing or new data might be lost
            if (
              spellChecksums[uuid].beforeUpdate ===
              spellChecksums[uuid].afterUpdate
            ) {
              newSpellList[uuid] = {
                ...spell.data,
                version: spell.version,
                lastChecksum: spellChecksums[uuid].remote,
              };
            } else {
              // Check if the server is more than one version ahead
              // console.log(
              //   "A spell changed during sync, restarting sync." + uuid
              // );
              // console.log("checksums", spellChecksums[uuid]);
              // console.log("new", currentSpells![uuid]);

              setTimeout(() => {
                performCustomSpellSync(retries + 1);
              }, retries * 2 * 2 * 1000);

              return;
            }
          } else {
            // This is a new spell
            newSpellList[uuid] = {
              ...spell.data,
              version: spell.version,
              lastChecksum: spellChecksums[uuid].remote,
            };
          }
        }

        // console.log(
        //   `Custom spell sync success! Updating ${
        //     Object.keys(newSpellList).length
        //   } spells.`,
        //   spellChecksums
        // );

        store.dispatch({
          type: spellbookActions.SPELL_CLOUD_SYNC,
          spells: newSpellList,
        });
      })
      .catch((error) => {
        console.log("Custom spell sync failed: ", error);
      });
  }
};

// We do not want to run the count decrement with every second only the last 5 seconds
let cloudSyncFunction = (decreaseCountWith: number) => () => {
  const state = store.getState();

  // If cloud syncing is enabled, perform the cloud sync
  if (isLoggedIn()) {
    if (state.interfaceData!.cloudSyncCountdown === 0) {
      // If we are not already cloud syncing
      if (!state.interfaceData!.isCloudSyncing) {
        performCloudSync();

        // Reset the countdown
        store.dispatch({
          type: interfaceActions.SET_CLOUD_SYNC_COUNTDOWN,
          count: 30,
        });

        // First timeout should be 25 seconds
        setTimeout(cloudSyncFunction(25), 25000);
      } else {
        // Next timeout should be 1 second
        setTimeout(cloudSyncFunction(1), 1000);
      }
    } else {
      let newCount =
        state.interfaceData!.cloudSyncCountdown - decreaseCountWith;

      // Next timeout should be 1 second
      setTimeout(cloudSyncFunction(1), 1000);

      if (newCount > -1) {
        store.dispatch({
          type: interfaceActions.SET_CLOUD_SYNC_COUNTDOWN,
          count: newCount < 0 ? 0 : newCount,
        });
      }
    }
  } else {
    // Try agian in 1 second
    setTimeout(cloudSyncFunction(1), 1000);
  }
};

// Start cloud sync loop (start with an immediate sync)
cloudSyncFunction(30)();

const theme = createTheme(themeData);

let downloadData = async () => {
  const state = store.getState();

  if (
    Object.keys(state.interfaceData.spellList).length < 1 &&
    !state.interfaceData.spellListIsDownloading
  ) {
    if (typeof state.interfaceData.spellListLastUpdate === "number") {
      retrieveSpells(
        true,
        state.interfaceData.spellListLastUpdate
      )(store.dispatch);
    } else {
      retrieveSpells(true, 0)(store.dispatch);
    }
  } else if (!state.interfaceData.spellListIsDownloading) {
    if (typeof state.interfaceData.spellListLastUpdate === "number") {
      retrieveSpells(false, state.interfaceData.spellListLastUpdate);
    } else {
      retrieveSpells(false, 0)(store.dispatch);
    }
  }

  if (
    Object.keys(state.interfaceData.spellListCategories).length < 1 &&
    !state.interfaceData.spellListCategoryIsDownloading
  ) {
    if (typeof state.interfaceData.spellListCategoriesLastUpdate === "number") {
      retrieveCategorizedSpells(
        true,
        state.interfaceData.spellListCategoriesLastUpdate
      )(store.dispatch);
    } else {
      retrieveCategorizedSpells(true, 0)(store.dispatch);
    }
  } else if (!state.interfaceData.spellListCategoryIsDownloading) {
    if (typeof state.interfaceData.spellListCategoriesLastUpdate === "number") {
      retrieveCategorizedSpells(
        false,
        state.interfaceData.spellListCategoriesLastUpdate
      )(store.dispatch);
    } else {
      retrieveCategorizedSpells(false, 0)(store.dispatch);
    }
  }

  // If there are changelogs available show a message and then hide it after 10 seconds.
  getChangelog().then((changelog) => {
    if (changelog) {
      store.dispatch({
        type: interfaceActions.UPDATE_CHANGELOG,
        changelog: changelog,
      });
    }
  });
};
downloadData();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Application />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
