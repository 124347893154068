import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";
import ChipContainer from "../ChipContainer";
import { compose } from "redux";
import { connect } from "react-redux";
import { State } from "../../reducers";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import {
  addCustomSpell,
  removeCustomSpell,
} from "../../actions/spellbookActions";
import {
  ClassOrderedSpellList,
  ClassOrderedSpellListCustomSpell,
  Spell,
  SpellList,
} from "../../reducers/interfaceData";
import {
  combineSpellListCategories,
  combineSpellLists,
} from "../../tools/UserSpellTools";

const styles = (theme: Theme) => ({
  root: {
    width: 650,
  },
  firstTab: {
    width: 650,
  },
  spellbookName: {
    width: 300,
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  helpText: {
    color: "#888",
  },
  classSelector: {
    width: 300,
  },
  addButton: {
    width: 100,
  },
});

let customSpellLevelLabels = [
  "Cantrip",
  "1st level spell",
  "2nd level spell",
  "3rd level spell",
  "4th level spell",
  "5th level spell",
  "6th level spell",
  "7th level spell",
  "8th level spell",
  "9th level spell",
];

export interface CustomSpellSettingsProps {
  classes: ClassNameMap;
  spellListCategories: ClassOrderedSpellList;
  spellList: SpellList;
  addCustomSpell: (
    spellId: number | string,
    spellLevel: number,
    isPrepared: boolean
  ) => void;
  removeCustomSpell: (
    spellId: number,
    spellLevel: number,
    isPrepared: boolean
  ) => void;
}

function CustomSpellSettings(props: CustomSpellSettingsProps) {
  let { classes } = props;

  const [state, setState] = useState<{
    customSpellId: string;
    customSpellLevel: number;
    isPreparedSpell: boolean;
  }>({
    customSpellId: "0",
    customSpellLevel: 0,
    isPreparedSpell: false,
  });

  let customSpells: Array<{
    key: string;
    spellId: number;
    spellLevel: number;
    isPrepared: boolean;
    label: string;
  }> = [];

  if (typeof props.spellListCategories.Custom !== "undefined") {
    for (let [key, value] of Object.entries(props.spellListCategories.Custom)) {
      let spellLevel = parseInt(key.substring(4, 5));

      value.forEach((element: ClassOrderedSpellListCustomSpell | string) => {
        if (typeof element === "string") {
          return; // Thats an invalid value, skip it
        }

        if (props.spellList[element.spellId] === undefined) {
          return; // Spell does not exist, skip it
        }

        let name: string;

        if (
          typeof element.spellId === "undefined" ||
          element.spellId === "0" ||
          typeof element.spellId === "number"
        ) {
          name = "";
        } else {
          name = props.spellList[element.spellId].name;
        }

        let label = `${name} as a ${customSpellLevelLabels[spellLevel]}${
          element.isPrepared ? " (prepared)" : ""
        }`;

        customSpells.push({
          key: spellLevel + "-" + element.spellId + "-" + element.isPrepared,
          spellId:
            typeof element.spellId === "string"
              ? parseInt(element.spellId)
              : element.spellId,
          spellLevel: spellLevel,
          isPrepared: element.isPrepared,
          label: label,
        });
      });
    }
  }

  const onCustomSpellLevelSelect = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    value: any
  ) => {
    if (typeof value !== "undefined" && value !== null) {
      setState({
        ...state,
        customSpellLevel: value.props.value,
      });
    }
  };

  const onCustomSpellNameChange = (
    event: React.ChangeEvent<{}>,
    value: Spell | null
  ) => {
    if (typeof value !== "undefined" && value !== null) {
      setState({
        ...state,
        customSpellId: value.id,
      });
    }
  };

  const addCustomSpell = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (state.customSpellId !== "0") {
      props.addCustomSpell(
        state.customSpellId,
        state.customSpellLevel,
        state.isPreparedSpell
      );
    }
  };

  const removeCustomSpell = (item: any) => {
    props.removeCustomSpell(item.spellId, item.spellLevel, item.isPrepared);
  };

  const switchPreparedCheckmark = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => {
    setState({
      ...state,
      isPreparedSpell: !state.isPreparedSpell,
    });
  };

  return (
    <Grid
      direction="column"
      spacing={2}
      container
      className="tour-spellbook-custom-spells"
    >
      <Grid item>
        <Typography className={classes.helpText}>
          If you have patron spells or spells you've learned from other sources,
          you can add them here. You can create your own spells from the menu.
        </Typography>
      </Grid>
      <Grid item>
        <Autocomplete
          id="custom-spells"
          style={{
            width: "100%",
          }}
          options={Object.values(props.spellList).filter(
            (option) => typeof option.name !== "undefined"
          )}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          filterSelectedOptions
          onChange={onCustomSpellNameChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Spell"
              size="small"
              placeholder="Start typing..."
            />
          )}
        />
      </Grid>
      <Grid item>
        <Grid direction="row" alignItems="center" spacing={2} container>
          <Grid item>
            <FormControl
              style={{ width: 100 }}
              variant="outlined"
              size="small"
              className={classes.formControl}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Spell level
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={state.customSpellLevel}
                onChange={onCustomSpellLevelSelect}
                label="Spell level"
              >
                <MenuItem value={0}>Cantrip</MenuItem>
                <MenuItem value={1}>1st</MenuItem>
                <MenuItem value={2}>2nd</MenuItem>
                <MenuItem value={3}>3rd</MenuItem>
                <MenuItem value={4}>4th</MenuItem>
                <MenuItem value={5}>5th</MenuItem>
                <MenuItem value={6}>6th</MenuItem>
                <MenuItem value={7}>7th</MenuItem>
                <MenuItem value={8}>8th</MenuItem>
                <MenuItem value={9}>9th</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControlLabel
              className={classes.helpText}
              control={
                <Checkbox
                  checked={state.isPreparedSpell}
                  onChange={switchPreparedCheckmark}
                  name="PreparedCheck"
                  color="primary"
                />
              }
              label="This is a prepared spell"
            />
          </Grid>
          <Grid item>
            <Button
              onClick={addCustomSpell}
              variant="contained"
              color="primary"
              size="medium"
              disabled={state.customSpellId === "0"}
              className={classes.addButton}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <ChipContainer chips={customSpells} onDelete={removeCustomSpell} />
      </Grid>
    </Grid>
  );
}

const mapDispatchToProps = {
  addCustomSpell,
  removeCustomSpell,
};

const mapStateToProps = (state: State, ownProps: any) => ({
  ...ownProps,
  profile:
    state.spellbookData.profiles[state.spellbookData.selectedProfileId].current,
  spellListCategories: combineSpellListCategories(
    state.interfaceData.spellListCategories,
    state.spellbookData.userSpells
  ),
  spellList: combineSpellLists(
    state.interfaceData.spellList,
    state.spellbookData.userSpells
  ),
});

export default compose<React.FunctionComponent>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CustomSpellSettings);
